import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
    BrowserRouter, Route, Routes
} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Random from './components/Game/Random';
import Today from './components/Game/Today';
import './index.css';
import Game from './pages/Game';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index element={<Home />} />
                            <Route path="game" element={<Game />}>
                                <Route path="random" element={<Random />} />
                                <Route path="today" element={<Today />} />
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
