import { RefreshIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initGame, resetGame } from "../../store/reducers/game";
import baseAxios from "../../utils/axios";
import Grid from "../Grid";
import Button from "../Ui/Button";
import Modal from "../Ui/Modal";
import Share from "./Share";

export default function Random() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.game.apiWord?.id);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        loadWord(token);

        return dispatch(resetGame());
    }, []);

    const loadWord = async (token = null) => {
        const resp = await baseAxios.get(`words/${token ? token : ''}`);
        dispatch(initGame(resp.data));
    }

    const handleFinish = (isOk) => {
        setSuccess(isOk);
    }

    const handleNewGame = () => {
        setSuccess(false);
        dispatch(resetGame());
        loadWord();
    }

    return (<>
        <Grid onFinish={handleFinish} containerClassName="mb-4" />
        <Button onClick={handleNewGame} icon={RefreshIcon}>Nouveau mot</Button>
        <Modal open={success} onCancel={() => setSuccess(false)} onOk={handleNewGame}>
            Bravo tu as gagné !
            <Share />
        </Modal>
    </>);
}