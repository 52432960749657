import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const DEFAULT_CHAR = '-';
const INITIAL_STATE = {
    apiWord: null,
    tokenWord: null,
    board: null,
    row: 0,
    col: 0,
    correctionArray: [],
    alreadyFound: null,
}

export const updateRow = createAsyncThunk(
    'game/updateRow',
    async ({ check, word }, { fulfillWithValue, dispatch }) => {
        const TIMER = 200;
        await Promise.all(check.map((status, index) => new Promise(resolve => setTimeout(() => {
            dispatch(setCorrectionCol({ col: index, status }));
            resolve();
        }, TIMER * index))));
    }
)

export const gameSlice = createSlice({
    name: 'game',
    initialState: INITIAL_STATE,
    reducers: {
        initGame: (state, { payload }) => {
            state.tokenWord = payload.id;
            state.apiWord = payload;

            state.board = new Array(state.apiWord.nbTry).fill([]).map((row, index) => {
                row = new Array(state.apiWord.length).fill(DEFAULT_CHAR);
                if (index === 0) row[0] = state.apiWord.letter;
                return row;
            });
            state.alreadyFound = new Array(state.apiWord.length).fill(false);
            state.row = 0;
            state.col = 0;
            state.correctionArray = [];
        },
        resetGame: () => INITIAL_STATE,
        finish: (state, { payload }) => {
            if (payload) {
                state.correctionArray[state.row] = new Array(state.board[0].length).fill(2);
            }
        },
        updateCurrentCol: (state, { payload }) => {
            state.board[state.row][state.col] = payload;
            state.col = Math.min(state.col + 1, state.apiWord.length);
        },
        setCorrectionCol: (state, { payload }) => {
            if (!state.correctionArray[state.row]) state.correctionArray[state.row] = [];
            state.correctionArray[state.row][payload.col] = payload.status;
        },
        rewindCol: (state) => {
            if (state.col === state.board[state.row].length) {
                state.board[state.row][state.col - 1] = DEFAULT_CHAR;
                state.col = state.col -2;
            } else {
                state.board[state.row][state.col] = DEFAULT_CHAR;
                state.col = Math.max(state.col -  1, 0);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateRow.fulfilled, (state, { meta: { arg }}) => {
                state.col = 0;
                state.alreadyFound = arg.check.map((s, index) => s === 2 ? arg.word[index] : state.alreadyFound[index]);
                state.row = state.row + 1;
                state.board[state.row] = state.alreadyFound.map(c => c ? c : DEFAULT_CHAR);
            })
    },
})

// Action creators are generated for each case reducer function
export const { initGame, resetGame, finish, updateCurrentCol, setCorrectionCol, rewindCol } = gameSlice.actions;

export default gameSlice.reducer;