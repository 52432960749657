import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useEventListener from "../../hooks/useEventListener";
import { finish, rewindCol, updateCurrentCol, updateRow } from "../../store/reducers/game";
import axios from "../../utils/axios";
import Modal from "../Ui/Modal";
import Cell from "./Cell";

export const DEFAULT_CHAR = '-';

export default function Grid({ onFinish, containerClassName }) {
    const dispatch = useDispatch();
    const currentRow = useSelector(state => state.game.row);
    const currentCol = useSelector(state => state.game.col);
    const board = useSelector(state => state.game.board);
    const check = useSelector(state => state.game.correctionArray);
    const data = useSelector(state => state.game.apiWord);

    const [modal, setModal] = useState(null);

    const checkWord = useCallback((userWord) => {
        axios.post(`/words/check/${data.id}`, { word: userWord })
            .then(({ data }) => {
                if (data.check) {
                    dispatch(updateRow({
                        check: data.check,
                        word: userWord,
                    }));
                } else if (data.word) {
                    dispatch(finish(data.success));
                    onFinish(data.success);
                }
            })
            .catch(({ response }) => {
                if (response?.status === 400) {
                    setModal(response.data.message);
                }
            })
    }, [onFinish, board, currentRow, data]);

    const handleChange = useCallback(({ key, keyCode, ctrlKey, altKey }) => {
        if (ctrlKey || altKey) return;
        if (keyCode >= 65 && keyCode <= 90 && currentCol < data.length) {
            dispatch(updateCurrentCol(key))
        } else if (key === 'Backspace') {
            dispatch(rewindCol());
        } else if (key === 'Enter' && board[currentRow].filter(c => c !== DEFAULT_CHAR).length === data.length) {
            checkWord(board[currentRow].join(''))
        }
    }, [data, board, currentRow, currentCol, checkWord]);

    useEventListener('keydown', handleChange);

    return (<>
        <div className={`${containerClassName}`}>
            {board?.map((row, rowIndex) =>
                <div className={`flex ${rowIndex > currentRow ? 'opacity-40' : ''}`} key={rowIndex}>
                    {row.map((cell, cellIndex) =>
                        <Cell
                            key={cellIndex}
                            status={check?.[rowIndex]?.[cellIndex]}
                            active={rowIndex === currentRow && cellIndex === currentCol}
                        >{cell}</Cell>
                    )}
                </div>
            )}
        </div>
        <Modal open={modal !== null} onCancel={() => setModal(null)} onOk={() => setModal(null)}>{modal}</Modal>
    </>);
}