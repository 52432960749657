import { Outlet } from "react-router-dom";
import Footer from "./components/Ui/Footer";
import Header from "./components/Ui/Header";

export default function App() {
    return (
        <main className="min-h-screen flex flex-col md:justify-between text-white bg-gradient-to-tr from-[#614385] to-[#516395]">
            <Header />
            <Outlet />
            <Footer />
        </main>
    )
}
