import { CalendarIcon, RefreshIcon, UserGroupIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetGame } from "../store/reducers/game";

export default function Home() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetGame());
    }, [])


    return (
        <div className="flex flex-col items-center text-3xl justify-center md:flex-1">
            <Link to="game/random" className="mb-4 py-2 px-4 border-4 rounded flex items-center transition-all hover:skew-y-2 hover:scale-105">
                <RefreshIcon className="h-7 w-7 mr-2" />
                Aléatoire
            </Link>
            <Link to="game/today" className="mb-4 py-2 px-4 border-4 rounded flex items-center transition-all hover:skew-y-2 hover:scale-105">
                <CalendarIcon className="h-7 w-7 mr-2" />
                Mot du jour
            </Link>
            <Link to="" className="py-2 px-4 border-4 rounded flex items-center line-through opacity-20 cursor-default">
                <UserGroupIcon className="h-7 w-7 mr-2" />
                Multijoueur
            </Link>
        </div>
    );
}
