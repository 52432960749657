import { Link } from "react-router-dom";

export default function Header() {
    return (
        <header className="bg-white/25 mb-4 py-4 w-full flex justify-center">
            <h1 className="text-6xl flex-1 text-center">
                <Link to="/">SUMOT</Link>
            </h1>
        </header>
    )
}