import { Outlet } from "react-router-dom";

function Game() {
    return (
        <div className="flex flex-col items-center justify-center md:flex-1">
            <Outlet />
        </div>
    );
}

export default Game;
