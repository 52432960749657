import { useMemo } from "react";
import { DEFAULT_CHAR } from ".";

export default function Cell({ children, status, active }) {
    const statusClass = useMemo(() => {
        if (children === DEFAULT_CHAR) return '';
        switch (status) {
            case 1:
                return 'bg-amber-500';
            case 2:
                return 'bg-red-600';
            default:
                return 'bg-white/25';
        }
    }, [status, children]);

    const activeClass = useMemo(() => active ? 'scale-110 ring-2 ring-white' : '', [active]);

    return (
        <div className={`${statusClass} ${activeClass} transition-all duration-300 w-10 sm:w-16 h-10 sm:h-16 m-1 flex items-center justify-center uppercase border border-gray-300 shadow-sm text-xl font-bold`}>
            {children}
        </div>
    );
}