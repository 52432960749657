import { useSelector } from "react-redux";

export default function Share() {
    const grid = useSelector(state => state.game.correctionArray);
    const maxTry = useSelector(state => state.game.board?.length);

    return (<div className="border rounded px-4 py-6 bg-black/50">
        SUMOT {grid.length} / {maxTry}
        <br />
        {grid.map((row, index) => <div key={index}>
            {row.map(col => {
                if (col === 2) return '🟥';
                if (col === 1) return '🟡';
                if (col === 0) return '🟦';
                return '';
            })}
        </div>)}
        {window.location.origin}
    </div>)
}