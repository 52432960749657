import Button from './Button';
import { Dialog } from '@headlessui/react';

export default function MyDialog({ title, description, children, open, onOk, onCancel }) {
    return (
        <Dialog open={open} onClose={onCancel} className="fixed z-10 inset-0 overflow-y-auto flex items-center text-white">
            <Dialog.Overlay className="fixed inset-0 bg-black/75" />
            <div className="relative bg-gradient-to-tr from-[#614385] to-[#516395] rounded mx-auto w-96 px-5 py-4">
                {title && <Dialog.Title>{title}</Dialog.Title>}
                {description && <Dialog.Description>{description}</Dialog.Description>}
                <div className="text-xl">{children}</div>
                <div className="flex justify-center mt-4">
                    <Button onClick={onOk} className="mr-2">Ok</Button>
                    <Button onClick={onCancel}>Annuler</Button>
                </div>
            </div>
        </Dialog>
    )
}